<template>
  <div id="divDataPolicyWrapper">
    <VueWrapperNarrow
      :style="{
        maxWidth: '80%',
        paddingTop: isOneTrustPublic ? '0' : '60px',
      }"
    >
      <VueHeadline v-if="!isOneTrustPublic" level="2" weightLevel="3">{{
        item.header
      }}</VueHeadline>
      <VueText
        :style="{
          paddingTop: isOneTrustPublic ? '0' : '20px',
        }"
        class="contract-body"
        weightLevel="2"
        sizeLevel="4"
        color="grey-40"
        v-html="item.text"
      ></VueText>
    </VueWrapperNarrow>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'DataPolicy',
  components: {
    VueText,
    VueHeadline,
    VueWrapperNarrow,
  },
  props: {
    isOneTrustPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: {
        header: 'Gizlilik Bildirimi',
        text: '',
      },
    };
  },
  created() {
    StaticContent.getPublicStaticContent('VeriPolitikalari').then(res => {
      if (res.data.Data) {
        this.item.text = res.data.Data.content;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.contract-body {
  line-height: 1.71;
  font-weight: 300;
}
</style>
